import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);


/**
 * Base
 */
// Debug
const gui = new dat.GUI()
// dont show gui
gui.hide()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const states = {
    loaderDone: false,
    onCv: false,
}

const camDistance = {
    large: 20,
    small: 13,
    current: innerWidth/innerHeight > 4/3 ? 20 : 13,
}

let face = 3;

let cv = null;


/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

let mixer = null
let mixerBird = null;

gltfLoader.load(
    '/models/victor/glTF/onlyCardAnim.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(3, 3, 3)
        scene.add(gltf.scene)

        // get mesh by name
        const mesh = gltf.scene.children.find(child => child.name === 'textFloor001')
        const meshCardBack = gltf.scene.children.find(child => child.name === 'cardBack001')
        const cardBackElems = gltf.scene.children.find(child => child.name === 'Cube036')
        const letters = []
        letters.push(gltf.scene.children.find(child => child.name === 'C001'))
        letters.push(gltf.scene.children.find(child => child.name === 'D001'))
        letters.push(gltf.scene.children.find(child => child.name === 'V1001'))
        letters.push(gltf.scene.children.find(child => child.name === 'V2001'))
        letters.push(gltf.scene.children.find(child => child.name === 'E1001'))
        letters.push(gltf.scene.children.find(child => child.name === 'E2001'))
        letters.push(gltf.scene.children.find(child => child.name === 'M001'))
        letters.push(gltf.scene.children.find(child => child.name === 'R1001'))
        letters.push(gltf.scene.children.find(child => child.name === 'R2001'))
        letters.push(gltf.scene.children.find(child => child.name === 'O1001'))
        letters.push(gltf.scene.children.find(child => child.name === 'O2001'))
        letters.push(gltf.scene.children.find(child => child.name === 'L001'))
        letters.push(gltf.scene.children.find(child => child.name === 'I001'))
        letters.push(gltf.scene.children.find(child => child.name === 'T001'))

        cv = gltf.scene.children.find(child => child.name === 'CV')

        // not display meshCardBack
        meshCardBack.visible = false
        cardBackElems.visible = false
        cv.visible = false

        mesh.position.z = 0.145
        gui.addColor(mesh.material, 'color').name('color')
        gui.add(mesh.position, 'z').min(0.1).max(0.156).step(0.001).name('zPosition')
//gsap change opacity of the floor
        gsap.to(floor.material, {opacity: 0, duration: 1.4, ease: "sine.inOut", delay: 4})
        gsap.to(mesh.position, {z: 0.0797605961561203, duration: 2, ease: "sine.inOut", delay: 5})
        gsap.to(mesh, {visible: false, delay: 6})
        gsap.to(mesh, {visible: true, delay: 7.5})
        gsap.to(meshCardBack, {visible: true, delay: 7.5})
        gsap.to(cardBackElems, {visible: true, delay: 7.5})
        gsap.to(cv, {visible: true, delay: 7.5})
        //gsap change color of the cv
        const overlay = document.querySelector('.overlay')
        gsap.to(overlay, {opacity: 1, duration: 1, delay: 7.5})

        setTimeout(() => {
            for(const letter of letters) {
                letter.visible = false
            }
        },8000)

// gsap change opacity of the logo
        //gsap.to(document.querySelector('.logoStroke'), {opacity: 0, duration: 1.4, ease: "sine.inOut", delay: 7})
        // Animation
        mixer = new THREE.AnimationMixer(gltf.scene)
        setTimeout(() => {
            for (let i = 0; i < gltf.animations.length; i++) {
                const animation = gltf.animations[i];
                const action = mixer.clipAction(animation);
                action.loop = THREE.LoopOnce;
                action.clampWhenFinished = true;
                // decrease the speed of the animation
                action.setDuration(1.5);
                action.play();
            }
            states.loaderDone = true
            setTimeout(() => {
                face = 1
            }, 1500)
            document.querySelector('.clickZone').style.display = 'flex'
        }, 6000)

        // play only one time

    }
)

gltfLoader.load(
    '/models/victor/glTF/birdAnim.gltf',
    (gltf) =>
    {
        gltf.scene.scale.set(3, 3, 3)
        // set frustum culling to false to avoid the bird to disappear to all children
        gltf.scene.traverse( function( object ) { object.frustumCulled = false; } );
        scene.add(gltf.scene)


        setTimeout(() => {
            // play animation named "mvt"
            mixerBird = new THREE.AnimationMixer(gltf.scene)
            const animation = gltf.animations.find(animation => animation.name === 'mvt')
            const action = mixerBird.clipAction(animation)
            action.loop = THREE.LoopOnce
            action.clampWhenFinished = true
            action.play()
            // on finish animation play idle animation
            setTimeout(() => {
                const animation = gltf.animations.find(animation => animation.name === 'idle')
                const action = mixerBird.clipAction(animation)

                // play it slowly
                action.setDuration(30)
                action.play()
            }, 7050);
        }, 4000)


    }
)

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(100, 100),
    // set the material to a new MeshStandardMaterial with sRGB encoding
    new THREE.MeshStandardMaterial({
        color: 0x5ab9b3,
        roughness: 0.5,
        metalness: 0
    })
)

floor.position.z = 0.6
// Set the object to sRGB encoding
floor.material.color.convertSRGBToLinear()
scene.add(floor)

floor.material.transparent = true

// gui floor opacity
gui.add(floor.material, 'opacity').min(0).max(1).step(0.001).name('floorOpacity')


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.7)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.4)
directionalLight.position.set(- 5, 5, 10)
scene.add(directionalLight)

// add a new directionalLight, the same as the previous one and add the controls to the gui
const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.4)
directionalLight2.position.set(-5, 5, -10)

scene.add(directionalLight2)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    if (camera.aspect < 4/3) {
        camDistance.current = camDistance.small
    } else {
        camDistance.current = camDistance.large
    }
    if (face !== 3)
        camera.position.z = face == 1 ? camDistance.current : -camDistance.current

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.fov = Math.atan( Math.tan( fov * Math.PI / 360 ) / camera.aspect ) * 360 / Math.PI;
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */

// group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

const fov = 60
// Base camera
const camera = new THREE.PerspectiveCamera(Math.atan( Math.tan( fov * Math.PI / 360 ) / (sizes.width / sizes.height) ) * 360 / Math.PI, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, camDistance.current)
cameraGroup.add(camera)



/**
 * Cursor
 */

const cursor = {}
cursor.x = 0
cursor.y = 0

const pointer = new THREE.Vector2();

window.onmousemove = (e) => {
    if( !states.isMobile) {
        cursor.x = e.clientX / sizes.width - 0.5
        cursor.y = e.clientY / sizes.height - 0.5
        pointer.x = (e.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(e.clientY / window.innerHeight) * 2 + 1;
    }
}

const turn = (dx, dz) => {
    if (face == 1) {
        face = 3;
        gsap.to(camera.position, {x: dx, duration: 0.7, ease: CustomEase.create("custom", "M0,0,C0,-0.394,0.436,0.654,0.565,0.775,0.609,0.816,0.78,1,1,1"), delay: 0})
        //gsap.to(camera.position, {x: 20, duration: 1, ease: "sine.inOut", delay: 0})
        gsap.to(camera.position, {z: dz, duration: 1.4, ease: "sine.inOut", delay: 0})
        gsap.to(camera.position, {y: 0, duration: 1.4, ease: "sine.inOut", delay: 0})
        gsap.to(camera.position, {x: 0, duration: 0.7, ease: CustomEase.create("custom", "M0,0,C0.666,0.014,0.464,1.72,1,1"), delay: 0.7})
        //gsap.to(camera.position, {x: 0, duration: 1, ease: CustomEase.create("custom", "M0,0,C0,0.204,0.304,0.373,0.507,0.512,0.728,0.664,1,0.802,1,1"), delay: 1})
        setTimeout(() => {
            face = 2;
        }, 1400)
    } else if (face == 2) {
        face = 3;
        gsap.to(camera.position, {x: -dx, duration: 0.7, ease: CustomEase.create("custom", "M0,0,C0,-0.394,0.436,0.654,0.565,0.775,0.609,0.816,0.78,1,1,1"), delay: 0})
        //gsap.to(camera.position, {x: -20, duration: 1, ease: "sine.inOut", delay: 0})
        gsap.to(camera.position, {z: -dz, duration: 1.4, ease: "sine.inOut", delay: 0})
        gsap.to(camera.position, {y: 0, duration: 1.4, ease: "sine.inOut", delay: 0})
        gsap.to(camera.position, {x: 0, duration: 0.7, ease: CustomEase.create("custom", "M0,0,C0.666,0.014,0.464,1.72,1,1"), delay: 0.7})
        //gsap.to(camera.position, {x: 0, duration: 1, ease: CustomEase.create("custom", "M0,1 C0.2,1 0.367,0.713 0.507,0.512 0.682,0.26 0.822,0 1,0 "), delay: 1})
        setTimeout(() => {
            face = 1;
        }, 1400)
    }
}

const turnRight = () => {
    turn(-camDistance.current, -camDistance.current);
}

const turnLeft = () => {
    turn(camDistance.current, -camDistance.current);
}

document.querySelector(".clickZone .left").onclick = () => {
    turnLeft()
}

document.querySelector(".clickZone .right").onclick = () => {
    turnRight()
}

document.querySelector(".overlay .return").onclick = () => {
    turnRight()
}

window.onclick = () => {
    if (states.onCv) {
        // redirect to the CV
        window.open("/cv.pdf", "_blank");
    }
}

const offset = new THREE.Vector3();
const distance = 18;

// function animation( time ) { // todo: mettre en gsap pour avoir une animation de 180 deg
//
//     offset.x = distance * Math.sin( time * 1 );
//     offset.z = distance * Math.cos( time * 1 );
//
//     camera.position.copy( new THREE.Vector3(0, 0, 0) ).add( offset );
//     camera.lookAt( 0, 0, 0 );
//
//     //renderer.render( scene, camera );
//
// }



// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0, 0)
controls.enableDamping = true
controls.enableZoom = false
controls.enablePan = false
controls.enableKeys = false

/**
* Points of interest
*/
const raycaster = new THREE.Raycaster()
const points = [
    {
        position: new THREE.Vector3(-4.661, 0.401, 0),
        element: document.querySelector('.logoStroke')
    }
]


// gui logoStroke opacity
gui.add(document.querySelector('.logoStroke').style, 'opacity').min(0).max(1).step(0.001).name('logoStrokeOpacity')

// for each points, gui control
const pointFolder = gui.addFolder('Point')
pointFolder.add(points[0].position, 'x').min(-4.7).max(-4.5).step(0.001).name('positionX')
pointFolder.add(points[0].position, 'y').min(-1).max(1).step(0.001).name('positionY')
pointFolder.add(points[0].position, 'z').min(-1).max(1).step(0.001).name('positionZ')



const raycasterFunction = () => {

    for(const point of points) {

        // Get 2D screen position
        const screenPosition = point.position.clone()
        screenPosition.project(camera)

        // update the picking ray with the camera and screen position
        raycaster.setFromCamera(screenPosition, camera)

        const translateX = screenPosition.x * sizes.width * 0.5
        const translateY = - screenPosition.y * sizes.height * 0.5
        point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    }
}

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0x000000, 0)
renderer.outputEncoding = THREE.sRGBEncoding;


/**
 * Animate
 */

// play animation

const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {
    raycasterFunction()
    raycaster.setFromCamera(pointer, camera)
    const intersects = raycaster.intersectObjects(scene.children)
    if(cv) {
        if(intersects.length) {
            if (intersects[0].object.name == "CV") {
                intersects[0].object.material.color.set(0x09052e)
                document.querySelector(".clickZone .left").classList.add("cv")
                document.querySelector(".clickZone .right").classList.add("cv")
                states.onCv = true
                //setCursor("pointer")
            } else {
                cv.material.color.set(0x030117)
                document.querySelector(".clickZone .left").classList.remove("cv")
                document.querySelector(".clickZone .right").classList.remove("cv")
                states.onCv = false
            }
        } else {
            cv.material.color.set(0x030117)
            document.querySelector(".clickZone .left").classList.remove("cv")
            document.querySelector(".clickZone .right").classList.remove("cv")
            states.onCv = false
        }
    }

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //animation( elapsedTime )

    // Model animation
    if (mixer) {
        mixer.update(deltaTime)
    }

    // Model animation
    if (mixerBird) {
        mixerBird.update(deltaTime)
    }

    const parallax = {}
    if (face == 1) {
        parallax.x = -cursor.x
        parallax.y = cursor.y
    } else {
        parallax.x = cursor.x
        parallax.y = cursor.y
    }

    if (states.loaderDone) {

        cameraGroup.position.x += (parallax.x * 5 - cameraGroup.position.x) * deltaTime * 5
        cameraGroup.position.y += (parallax.y * 5 - cameraGroup.position.y) * deltaTime * 5
    }

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)


   // if(elapsedTime < Math.PI/1) {
        //camera.position.x=0+10*(Math.sin(elapsedTime*1));
        //camera.position.z=0+10*(Math.cos(elapsedTime*1));
    //}

}

// // axes helper
// const axesHelper = new THREE.AxesHelper(5)
// // above object
// axesHelper.position.z = 0.5
// scene.add(axesHelper)

tick()
